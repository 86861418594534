import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DefaultPageContainer } from 'components/shared/ui/styles/Global.styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';

const gridItemProps = {
  item: true,
  xs: 12,
  sm: 4,
};

export const HistoryGridItem = styled(Grid)(({ theme }) => ({
  '&&': {
    paddingBottom: theme.spacing(2),
  },
}));
HistoryGridItem.defaultProps = gridItemProps;

export const HistoryPageContainer = styled(DefaultPageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  fontSize: 'small',
  paddingLeft: theme.spacing(0.5),
  color: '#555759',
}));

export const HistoryCaption = styled(Caption2)`
  color: #0000008a;
`;
