import { FC } from 'react';
import { useTranslations } from 'components/shared/i18n';
import {
  FilterItems,
  FilterSection,
  StyledFilterButton,
  StyledFilterLabel,
} from 'components/notes/filterBar/FilterBar.styles';
import { MenuItem, Select } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

export const FilterBar: FC = () => {
  const { t } = useTranslations();

  const handleChange: () => void = () => {
    // handle change
  };

  return (
    <FilterSection data-testid='filterSection'>
      <FilterItems>
        <Select
          data-testid='preferencesFilterDropdown'
          labelId='sort-by-select-label'
          id='sort-by'
          value={'newest'}
          label={t('common.sortBy')}
          style={{ width: '140px', height: '40px' }}
          onChange={handleChange}>
          <MenuItem value={'newest'}>{t('notes.newest')}</MenuItem>
          <MenuItem value={'oldest'}>{t('notes.oldest')}</MenuItem>
        </Select>
        <StyledFilterButton data-testid='filterListButton'>
          <FilterListIcon />
          <StyledFilterLabel>{t('common.filter')}</StyledFilterLabel>
        </StyledFilterButton>
      </FilterItems>
    </FilterSection>
  );
};
