import { FC } from 'react';
import { Grid } from '@mui/material';
import { StyledLink } from 'components/shared/ui/styles/Global.styles';
import {
  HistoryCaption,
  HistoryGridItem,
  HistoryPageContainer,
  InfoIcon,
} from 'components/notes/historyTab/HistoryTab.styles';
import { useTranslations } from 'components/shared/i18n';
import { Body1 } from '@ehi/ui';
import { Box } from '@mui/system';
import { FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';
import { DOUBLE_DASH } from 'utils/constants';

type HistoryCardProps = {
  createdBy: string;
  createdDatetime: string;
  bookingStation: string;
  bookingChannel: string;
  altResNum: string | undefined;
  prevResNum: string | undefined;
  gdsRecordLocator: string;
  tourAgentId: string | undefined;
};

export const HistoryTab: FC = () => {
  const { t } = useTranslations();
  const itemDisplay = (serviceValue: string | undefined): string => {
    return serviceValue ?? DOUBLE_DASH;
  };
  const linkDisplay = (serviceValue: string | undefined): JSX.Element => {
    return serviceValue ? (
      <StyledLink as='button' data-testid={'vehicle-class-link'}>
        <Body1 bold>{serviceValue}</Body1>
      </StyledLink>
    ) : (
      <Body1>{DOUBLE_DASH}</Body1>
    );
  };

  const historyData: HistoryCardProps = {
    createdBy: 'AA SABRE',
    createdDatetime: '12/05/2024 5:03PM CST',
    bookingStation: 'MSPE55',
    bookingChannel: 'GDS',
    altResNum: undefined,
    prevResNum: '628675309',
    gdsRecordLocator: '5A862',
    tourAgentId: undefined,
  };

  return (
    <HistoryPageContainer data-testid='historyPage'>
      <FlexiFlowCardInnerContainer style={{ width: '75%' }}>
        <Grid container data-testid='createdSection'>
          <HistoryGridItem>
            <HistoryCaption>{t('notes.history.createdBy')}</HistoryCaption>
            <Body1>{historyData.createdBy}</Body1>
          </HistoryGridItem>
          <HistoryGridItem>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <HistoryCaption>{t('notes.history.createdDateTime')}</HistoryCaption>
              <InfoIcon />
            </Box>
            <Body1>{historyData.createdDatetime}</Body1>
          </HistoryGridItem>
          <HistoryGridItem>
            <HistoryCaption>{t('notes.history.bookingStation')}</HistoryCaption>
            <Body1>{historyData.bookingStation}</Body1>
          </HistoryGridItem>
        </Grid>
        <Grid container data-testid='bookingAndResSection'>
          <HistoryGridItem>
            <HistoryCaption>{t('notes.history.bookingChannel')}</HistoryCaption>
            <Body1>{historyData.bookingChannel}</Body1>
          </HistoryGridItem>
          <HistoryGridItem>
            <HistoryCaption>{t('notes.history.altResNumber')}</HistoryCaption>
            <Body1>{itemDisplay(historyData.altResNum)}</Body1>
          </HistoryGridItem>
          <HistoryGridItem>
            <HistoryCaption>{t('notes.history.prevResNumber')}</HistoryCaption>
            <br />
            {linkDisplay(historyData.prevResNum)}
          </HistoryGridItem>
        </Grid>
        <Grid container data-testid='recordAndTourSection'>
          <Grid item xs={12} sm={4}>
            <HistoryCaption>{t('notes.history.gdsRecordLocator')}</HistoryCaption>
            <Body1>{historyData.gdsRecordLocator}</Body1>
          </Grid>
          <Grid item xs={12} sm={4}>
            <HistoryCaption>{t('notes.history.tourAgentId')}</HistoryCaption>
            <Body1>{itemDisplay(historyData.tourAgentId)}</Body1>
          </Grid>
        </Grid>
      </FlexiFlowCardInnerContainer>
    </HistoryPageContainer>
  );
};
